import aboutpic from './components/Access/newpic.png'

const header = {
  // all the properties are optional - can be left empty or deleted
  homepage:
    '/',
  title: 'WR.',
}

const about = {
  photo: aboutpic,
  // all the properties are optional - can be left empty or deleted
  name: 'Wasiq Rashid',
  role: 'Full Stack Software Engineer',
  description:
    'Rutgers University and App Academy Alumni. Highly interested in web/mobile development with both Python and Javascript. Currently learning more about the Cloud, Docker, and Kubernetes.',
  resume:
    'https://drive.google.com/file/d/1_gyRnbcvAGQkK9xHZf2mjQEnt_UY0U3Q/view',
  social: {
    linkedin: 'https://www.linkedin.com/in/wasiq-rashid-fsd',
    github: 'https://github.com/FatalBanana1',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Datcord',
    description: 'A full-stack web application cloned and inspired by Discord.',
    features: [
      'Servers',
      'Channels',
      'Channel Messages(Sockets)',
      'Memberships',
      'Theme',
      'Friendships',
      'Direct Messages(Sockets)',
    ],
    stack: [
      'Python',
      'JavaScript',
      'Websockets',
      'Flask',
      'SQLAlchemy',
      'Alembic',
      'Node.js',
      'React',
      'Redux',
      'PostgreSQL',
      'SQLite',
    ],
    sourceCode: 'https://github.com/FatalBanana1/datcord-project-FKPW.git',
    livePreview: 'https://datcord-main.onrender.com/',
  },
  {
    name: 'Swift',
    description:
      'A full-stack web application inspired by Productivity apps with a fresh take. I prioritized user experience with a focus on productivity. Let this give you a glimpse of my creativity and passion.',

    features: [
      'Workspaces',
      'Projects',
      'Tasks',
      'Subtasks',
      'Memberships(RBAC)',
      'Comments',
      'Likes',
      'Search(Autocomplete)',
    ],
    stack: [
      'Python',
      'JavaScript',
      'Flask',
      'SQLAlchemy',
      'Alembic',
      'Node.js',
      'React',
      'Redux',
      'PostgreSQL',
      'SQLite',
    ],
    livePreview: 'https://bananas-wr.onrender.com',
  },
  {
    name: 'Groupup',
    description: 'A full-stack web application cloned and inspired by Meetup.',
    features: [
      'Groups',
      'Memberships',
      'Group Images',
      'Events',
      'Attendances',
      'Search',
    ],
    stack: [
      'JavaScript',
      'Express.js',
      'Sequelize.js',
      'Node.js',
      'React',
      'Redux',
      'PostgreSQL',
      'SQLite',
    ],
    sourceCode: 'https://github.com/FatalBanana1/groupup-project-WR',
    livePreview: 'https://groupup-k8j2.onrender.com',
  },
  {
    name: 'Lee+=',
    description:
      'Youtube Channel about tech with full explanations covering how to approach different concepts such as Leetcode questions, React and Redux guides, Express.js, etc.',
    stack: [
      'JavaScript',
      'Python',
      'Express.js',
      'Sequelize.js',
      'Node.js',
      'React',
      'Redux',
      'PostgreSQL',
      'SQLite',
    ],
    livePreview: 'https://www.youtube.com/@leetwh',
  },
]

const languages = ['Python', 'JavaScript', 'SQL', 'TypeScript', 'HTML', 'CSS']

const skills = [
  // if there are no skills, Skills section won't show up
  'Git',
  'Node.js',
  'React',
  'React Native',
  'Redux',
  'Amazon Web Services',
  'Flask',
  'SQLAlchemy',
  'Alembic',
  'Express.js',
  'Sequelize.js',
  'PostgreSQL',
  'SQLite',
  'Docker',
  'Figma',
  'Websockets',
  'Socket.io',
  'Jinja',
]

const skills2 = [
  'RESTful APIs',
  'Object Oriented Programming(OOP)',
  'Agile Development',
  'Role Based Access Control(RBAC)',
  'Object Relational Mapping(ORM)',
  'Test Driven Development(TDD)',
]

const tech = [
  'Visual Studio Code',
  'PyCharm',
  'DBeaver',
  'pgAdmin',
  'Valentina Studio',
  'Postman API',
  'Insomnia',
  'Render',
  'Github',
]

const contact = {
  email: 'dark1knite@gmail.com',
}

export { header, about, projects, skills, contact, tech, languages }
