import uniqid from 'uniqid'
import GitHubIcon from '@material-ui/icons/GitHub'
import LaunchIcon from '@material-ui/icons/Launch'
import './ProjectContainer.css'
import swift from '../Access/swift.png'
import datcord from '../Access/datcord.png'
import groupup from '../Access/groupup.png'
import leet from '../Access/leet.png'

const ProjectContainer = ({ project }) => (
  <div className='project'>
    <h3>{project.name}</h3>

    {project.name.includes('Datcord') && (
      <img src={datcord} className='proj-pic' alt='project page' />
    )}
    {project.name.includes('Swift') && (
      <img src={swift} className='proj-pic' alt='project page' />
    )}
    {project.name.includes('Group') && (
      <img src={groupup} className='proj-pic' alt='project page' />
    )}
    {project.name.includes('Lee') && (
      <img src={leet} className='proj-pic' alt='project page' />
    )}

    <p className='project__description'>{project.description}</p>

    {project.features && (
      <div>
        <div className='project__stack-item underline'>Features</div>
        <ul className='project__stack'>
          {project.features.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      </div>
    )}

    {project.stack && (
      <div>
        <div className='project__stack-item underline'>Tech</div>
        <ul className='project__stack'>
          {project.stack.map((item) => (
            <li key={uniqid()} className='project__stack-item'>
              {item}
            </li>
          ))}
        </ul>
      </div>
    )}

    {project.sourceCode && (
      <a
        href={project.sourceCode}
        aria-label='source code'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <GitHubIcon />
      </a>
    )}

    {project.livePreview && (
      <a
        href={project.livePreview}
        aria-label='live preview'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <LaunchIcon />
      </a>
    )}
  </div>
)

export default ProjectContainer
